/* eslint-disable */
import React, { useContext } from 'react';
import PaymentContext from '../../context/Payment/Context';
import ProductContext from '../../context/Product/Context';
import GlobalContext from '../../context/Global/Context';
import useProductDataByCountry from '../../hooks/useProductDataByCountry';
import totalPriceCarts from '../../hooks/totalCarts';

const organizeDataPayments = () => {
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const { carts, products } = productContext;
  const { address, hasAddress } = paymentContext;
  const { customerId, isCitaFlow, country } = globalContext;
  const [productsToShow, setProductsToShow] = React.useState([]);
  const totalCarts = totalPriceCarts(carts, false);
  const { currencyCode } = carts[0].totalPrice;
  localStorage.setItem('email', carts[0].customerEmail);
  const checkPaymentInfo = (cart) => {
    const tmpResult = {
      importantCustomLineItem: false,
      importantLineItem: false,
      productInfo: []
    };

    let res = {}

    if (cart.customLineItems && cart.customLineItems.length > 0) {
      cart.customLineItems.forEach(item => {

        if (!item.custom?.fields) {
          tmpResult.importantCustomLineItem = false;
          tmpResult.productInfo.push({
            type: '',
            productKeyCRM: '',
            productCategory: ''
          });
          return
        }

        if (item.custom?.fields) {
          if (item.custom.fields.productCategory !== 'aligners') {
            tmpResult.importantCustomLineItem = false;
            tmpResult.productInfo.push({
              type: '',
              productKeyCRM: '',
              productCategory: '',
            });
            return
          }

          tmpResult.importantCustomLineItem = true;
          tmpResult.productInfo.push({
            type: 'customLineItem',
            productKeyCRM: item.custom.fields.productKeyCRM,
            productCategory: item.custom.fields.productCategory
          });
        }
      });
    }

    if (cart.lineItems && cart.lineItems.length > 0) {
      cart.lineItems.forEach(item => {
        const sku = item.variant?.sku;
        if (sku) {
          tmpResult.importantLineItem = true;
          if (sku === 'appointment') {
            tmpResult.productInfo.push({
              type: 'appointment',
              productKeyCRM: `${sku}-${country.toLowerCase()}`,
              productCategory: 'appointment',
            });
          } else if (sku.includes('pago-inicial')) {
            tmpResult.importantLineItem = true;
            tmpResult.productInfo.push({
              type: 'aligners',
              productKeyCRM: sku,
              productCategory: 'aligners',
            });
          } else {
            tmpResult.importantLineItem = false;
            tmpResult.productInfo.push({
              type: '',
              productKeyCRM: '',
              productCategory: '',
            });
          }
        }
      });
    }
    if (tmpResult.productInfo.length === 1)  {
      res = tmpResult.productInfo[0];
    }

    if (tmpResult.productInfo.length > 1) {
      let tmpItem = tmpResult.productInfo.find(item => item.type !== '') || null;
      if (tmpItem !== null && tmpItem?.type !== '') {
        res = tmpItem;
      } else {
        res = {
          type: '',
          productKeyCRM: '',
          productCategory: ''
        }
      }
    }
    return res;
  }

  if (productsToShow.length < products.length) {
    const converted = useProductDataByCountry(products, false, false);
    converted.forEach((obj) => {
      delete obj.images; // eslint-disable-line no-param-reassign
    });
    setProductsToShow(converted);
  }

  const getAddress = () => {
    if (typeof address.State === 'string') {
      return address.State;
    }
    return address.State[0];
  }

  const agentName = new URLSearchParams(location.search).get('agentName');
  const agentId = new URLSearchParams(location.search).get('agentId');
  const clinicId = new URLSearchParams(location.search).get('clinicId');
  const addresLocal = {
      Street: hasAddress ? address.Street : 'street',
      Address_Number: hasAddress ? address.Address_Number : '123',
      Zip_Code: hasAddress ? address.Zip_Code : '00000',
      Neighborhood: hasAddress ? address.Neighborhood : 'colony',
      State: hasAddress ? getAddress() : 'state',
      city: 'Ciudad',
      References: hasAddress ? address.References : 'reference',
    };
  
  

  const paymentInfo = checkPaymentInfo(carts[0]);
  const data = {
    payer: {
      address: addresLocal,
      name: localStorage.getItem('name'),
      phone: '000000000000',
      email: carts[0].customerEmail,
      customerId: customerId ? customerId : carts[0].anonymousId,
      identification: {
        docTypeCard: '',
        docValueCard: ''
      }
    },
    cartData: {
      id: carts[0].id,
      currency: currencyCode,
      totalPrice: totalCarts,
      totalLineItemQuantity: products.length,
      lineItems: productsToShow,
      productCategory: paymentInfo.productCategory || '',
      productKey: paymentInfo.productKeyCRM || '',
    },
  };

  if (agentId !== null && agentName !== null && clinicId !== null) {
    data.salesAgentId = agentId;
    data.nameAgent = agentName;
    data.clinicId = clinicId;
  }

  localStorage.setItem('address', JSON.stringify(addresLocal));

  return data;
};

export default organizeDataPayments;
